<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="300"
    v-model="dialog"
    persistent
  >
    <v-container class="background rounded-lg">
      <v-row class="ma-0"
        ><v-col cols="11" class="primary--text">{{ $t(type + ".edit") }}</v-col
        ><v-col cols="1" class="pa-0"
          ><v-icon
            class="text-right mt-2"
            color="secondary"
            @click="modalAction"
            >mdi-close</v-icon
          ></v-col
        ></v-row
      ><v-row class="ma-0 mb-4"
        ><v-divider class="mx-2 mb-6"></v-divider
      ></v-row>
      <v-row class="pa-0 ma-0" justify="center" v-if="!!!file">
        <v-col cols="10" sm="8" md="10" class="pa-0">
          <v-btn
            color="secondary"
            block
            outlined
            large
            rounded
            @click="addData"
            style="text-transform: none"
          >
            {{ $t(type + ".edit") }}
            <v-icon right dark> mdi-paperclip </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" justify="center">
        <v-file-input
          small-chips
          show-size
          class="mx-4"
          :class="file != null ? '' : 'd-none'"
          :id="type"
          clear-icon="mdi-delete"
          prepend-icon=""
          truncate-length="15"
          v-model="file"
          :accept="data[type].accept"
        ></v-file-input>
      </v-row>
      <v-row justify="end" class="ma-0 mt-2"
        ><v-btn
          color="secondary"
          class="rounded-lg mt-4"
          style="text-transform: none"
          large
          :loading="loadingButton"
          :disabled="!!!file"
          @click="ValidateChangeData"
          >{{ $t("operation.save") }}</v-btn
        ></v-row
      >
    </v-container>
  </v-dialog>
</template>
<script>
import PharmacyService from "@/services/PharmacyService.js";
import CandidateService from "@/services/CandidateService.js";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean
    },
    type: {
      required: true,
      default: "",
      type: String
    }
  },
  data() {
    return {
      loadingButton: false,
      file: null,
      data: {
        video: {
          call: "uploadVideo",
          accept: "video/*"
        },
        logo: {
          call: "uploadLogo",
          accept: ".jpg,.png,.jpeg"
        },
        groupLogo: {
          call: "uploadGroupLogo",
          accept: ".jpg,.png,.jpeg"
        },
        cv: {
          call: "uploadCurriculum",
          accept: ".pdf,.doc,.docx"
        },
        avatar: {
          call: "uploadAvatar",
          accept: ".jpg,.png,.jpeg"
        },
        other_files: {
          call: "uploadOtherFiles",
          accept: ".jpg,.png,.jpeg,.pdf"
        }
      }
    };
  },
  computed: {
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ groupId: "user/groupId" }),
    ...mapGetters({ userType: "user/type" })
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ValidateChangeData() {
      if (this.type == "video") {
        this.validateFile(this.file);
      } else {
        this.changeData();
      }
    },
    async changeData() {
      this.loadingButton = true;
      var service =
        this.userType == "candidate" ? CandidateService : PharmacyService;
      this.enableLoading();

      try {
        // TODO refactor this ****
        await service[this.data[this.type].call](
          this.type === "groupLogo" ? this.groupId : this.childId,
          this.file
        );
        this.notifySucces();
        this.modalAction();
        this.timeOut();
      } catch (error) {
        this.notifyError();
      } finally {
        this.disableLoading();
        this.loadingButton = false;
      }
    },
    modalAction() {
      this.$emit("close");
    },
    timeOut() {
      setTimeout(function() {
        location.reload();
      }, 500);
    },
    validateFile(file) {
      var instance = this;
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        if (video.duration < 50) {
          instance.notifyErrorDuration();
          return;
        }
        instance.changeData();
      };

      video.src = URL.createObjectURL(file);
    },
    notifyError() {
      this.$notify({
        title: this.$i18n.t("notification.change_" + this.type + "_error"),
        text: this.$i18n.t("notification.change_" + this.type + "_error"),
        type: "error"
      });
    },
    notifyErrorDuration() {
      this.$notify({
        title: this.$i18n.t("notification.video_duration_error"),
        type: "error"
      });
    },
    notifySucces() {
      this.$notify({
        title: this.$i18n.t("notification.change_" + this.type),
        text: this.$i18n.t("notification.change_" + this.type),
        type: "success"
      });
    },
    addData() {
      document.getElementById(this.type).click();
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom;
}
.theme--light.v-divider {
  border-color: var(--v-secondary-base) !important;
}
</style>
