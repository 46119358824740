export default [
    {
        title: "profile.edit_pharmacy",
        icon: "mdi-pencil",
        router: "edit-profile",
        key: "edit"
    },
    {
        title: "profile.update_logo",
        icon: "mdi-account-circle-outline",
        router: "",
        key: "logo"
    },
    {
        title: "profile.update_video",
        icon: "mdi-play-circle",
        router: "",
        key: "video"
    },
]