<template>
  <v-container v-if="mounted">
    <change-modal
      v-if="changeFiles"
      :dialog="changeFiles"
      :type="modalType"
      @close="closeFiles"
    />
    <video-player
      v-if="pharmacy.video"
      @closeVideoPlayer="toggleVideoPlayer"
      :showVideoPlayer.sync="videoPlayer"
      :src="pharmacy.video"
    /><v-row class="pa-0 ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="ml-2">
          <v-list-item-avatar tile size="80" v-if="pharmacy.logo">
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              :src="pharmacy.logo"
              transition="scale-transition"
          /></v-list-item-avatar>
          <v-list-item-avatar tile size="80" v-else>
            <v-img
              alt="user avatar"
              class="shrink"
              contain
              src="@/assets/apotalent/Isotipo white.svg"
              @click="handleEvent('logo')"
              style="cursor: pointer"
              transition="scale-transition"
          /></v-list-item-avatar>
          <v-list-item-content>
            <v-col cols="10" class="pa-0 ma-0"
              ><v-list-item-title class="text-subtitle-1 primary--text">{{
                pharmacy.commercial_name
              }}</v-list-item-title>
              <v-list-item-subtitle
                class="primary--text text-caption font-weight-light opacity"
                >{{
                  `${pharmacy.address.locality.name}, ${pharmacy.address.locality.state.name}`
                }}</v-list-item-subtitle
              >
            </v-col>
            <v-col cols="2" class="mb-6 pa-0 ma-0 text-end">
              <v-menu left bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
                </template>
                <!-- Profile dropdown list -->
                <v-list class="rounded-lg">
                  <v-list-item @click="() => {}" class="justify-end">
                    <v-icon color="primary">mdi-close</v-icon>
                  </v-list-item>
                  <v-row
                    dense
                    v-for="(option, index) in profileOptions"
                    :key="index"
                  >
                    <v-list-item
                      :to="option.router"
                      @click="
                        option.router.length > 0 ? {} : handleEvent(option.key)
                      "
                    >
                      <v-list-item-title
                        class="font-weight-light secondary--text"
                      >
                        <v-icon color="primary">{{ option.icon }}</v-icon>
                        {{ $tc(option.title, 2) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-row>
                </v-list>
              </v-menu>
            </v-col>
            <div></div>
            <div class="">
              <v-btn
                class="pa-0 mx-1 mb-1"
                :color="!pharmacy.video ? 'error' : 'success'"
                @click="
                  !pharmacy.video ? handleEvent('video') : toggleVideoPlayer()
                "
              >
                <v-icon> mdi-play-circle </v-icon>
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item></v-col
      ></v-row
    >
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("field.pharmacy_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>

    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle v-if="pharmacy.number" class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.pharmacy_number") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ pharmacy.number }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.soe") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ pharmacy.soe }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.pharmacy_holder") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ pharmacy.pharmacy_owner.full_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.commercial_name") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.commercial_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.address") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.address.formatted || pharmacy.address.raw }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="pharmacy.address.locality.postal_code"
            >
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.postal_code") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.address.locality.postal_code }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text">{{
                $t("field.province") + ":"
              }}</span>
              <span class="primary--text font opacity"
                >{{ pharmacy.address.locality.state.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text"
                >{{ $t("field.country") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.address.locality.state.country.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1" v-if="pharmacy.group">
              <span class="text-weight-bold primary--text"
                >{{ $t("field.pharmacy_group") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.group }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("field.billing_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.fiscal_name") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ pharmacy.fiscal_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">{{
                $t("field.cif") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{ pharmacy.cif }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.phone_number") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.phone_number }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.address") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{
                  pharmacy.billing_address.formatted ||
                    pharmacy.billing_address.raw
                }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="pharmacy.billing_address.locality.postal_code"
            >
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.postal_code") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.billing_address.locality.postal_code }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text"
                >{{ $t("field.province") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.billing_address.locality.state.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text"
                >{{ $t("field.country") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ pharmacy.billing_address.locality.state.country.name }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("field.pharmacist_holder") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="6" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>

    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="text-subtitle-2 font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">{{
                $t("field.full_name") + ":"
              }}</span>
              <span class="primary--text opacity font">
                {{ pharmacy.pharmacy_owner.full_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">{{
                $t("field.phone_number") + ":"
              }}</span>
              <span class="primary--text font opacity font"
                >{{ pharmacy.pharmacy_owner.phone_number }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("register.other_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="text-subtitle-2 font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle v-if="pharmacy.web" class="my-1">
              <span class="text-weight-bold primary--text font">{{
                $t("field.web") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{ pharmacy.web }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="pharmacy.computer_programs || pharmacy.othersComputerPrograms" class="my-1">
              <span class="text-weight-bold primary--text font">{{
                $t("register.computer_programs") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{ pharmacy.computer_programs.name || pharmacy.othersComputerPrograms.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1" v-if="pharmacy.employees_number !=null">
              <span class="text-weight-bold primary--text font">{{
                $t("field.employee_number") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{  pharmacyWorkerNumber[pharmacy.employees_number][1] }}
              </span>
            </v-list-item-subtitle>
            <!-- <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >Logo de la farmacia:</span
              >
              <span class="primary--text font opacity">logo farmacia.jpg </span>
            </v-list-item-subtitle> -->
            <v-list-item-subtitle
              class="my-1"
              v-if="pharmacy.contacts.length > 0"
            >
              <span class="text-weight-bold primary--text font">{{
                $t("field.aditional_contact") + ":"
              }}</span>
            </v-list-item-subtitle>
            <div v-for="(contact, index) in pharmacy.contacts" :key="index">
              <v-list-item-subtitle class="my-1">
                <span class="text-weight-bold primary--text font"
                  >{{ $t("field.name") + ":" }}
                </span>
                <span class="primary--text font opacity">{{
                  contact.full_name
                }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="my-1">
                <span class="text-weight-bold primary--text font"
                  >{{ $t("field.email") + ":" }}
                </span>
                <span class="primary--text font opacity font"
                  >{{ contact.email }}
                </span>
              </v-list-item-subtitle>
                <v-list-item-subtitle class="my-1">
                <span class="text-weight-bold primary--text font"
                  >{{ ("Programa informatico utilizado") + ":" }}
                </span>
                <span class="primary--text font opacity">{{
                 ("Farmacop") 
                }}</span>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="my-1">
                <span class="text-weight-bold primary--text font"
                  >{{ $t("field.phone_number") + ":" }}
                </span>
                <span class="primary--text font opacity">{{
                  contact.phone_number
                }}</span>
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Edit data modal
import ChangeModal from "@/components/modals/ChangeModal.vue";
// video player
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
// Menu options
import profileOptions from "@/components/profiles/PharmacyProfileOptions.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { VideoPlayer, ChangeModal },
  data() {
    return {
      profileOptions: profileOptions,
      mounted: false,
      modalType: null,
      changeFiles: false,
      videoPlayer: false
    };
  },
  props: {},
  async created() {
    this.enableLoading();
    this.getPharmacyWorkerNumber();
    await this.getPharmacyById(this.childId);
    this.mounted = true;
    this.disableLoading();
  },

  computed: {
    ...mapGetters({ computerPrograms: "core/computerPrograms" }),
    ...mapGetters({ pharmacy: "pharmacy/pharmacy" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ pharmacyWorkerNumber: "core/pharmacyWorkerNumber" })
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getPharmacyById: "pharmacy/getPharmacyById" }),
    ...mapActions({ updateChildId: "pharmacy/updateChildId" }),
    ...mapActions({ getPharmacyWorkerNumber: "core/getPharmacyWorkerNumber" }),

    handleEvent(opt) {
      this.modalType = opt;
      this.changeFiles = true;
    },
    closeFiles() {
      this.changeFiles = !this.changeFiles;
    },
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    }
  }
};
</script>
<style scoped>
.font {
  font-size: 13px;
}
.opacity {
  opacity: 0.6;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
